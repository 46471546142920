import React from 'react'
import styles from './Home.module.css'

const Home = () => {
   
    return (
        <div className={styles.pageContainer}>
            
            <section className={styles.hero}>
                <h1>Redemption Steel Services</h1>
                <div className={styles.paragraph}>
                    <p></p>
Redemption Steel Services is a complex metalwork fabricator specializing in welding medium to large structures and metal parts. RS is a Wadeville based
metal cutter that processes raw materials with state-of-the-art CNC plate cutting - laser, oxy-fuel flame, high-definition plasma cutting and bevelling. We
precisely form, bend, and roll metal to exacting specifications. Our certified welders are experts at bonding a full array of metal types and intricate
construction. On-site CNC machining centres are at the ready to provide the necessary machined surfaces and tolerances. We also offer metal assemblies
and surface finishing to produce completed products.
                </div>
            </section>
        </div>
    )
}

export default Home
