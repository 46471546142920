import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Footer.module.css'
import FabButton from '../Fab/Fab';


const Footer = () => {
    const location = useLocation()
    const [user, setUser ] = useState(
         JSON.parse(localStorage.getItem('profile') || '{}')  )

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile') || '{}'))
    }, [location])
console.log(user)
//get token from user
const token = user.token 
    return (
        <footer>
            <div className={styles.footerText}>
         <span><a href="https://www.redemptionsteel.co.za" target="_blank" rel="noopener noreferrer">Redemption Steel</a></span>
            </div>
            {token !== undefined && (<FabButton />)}
        </footer>
    )
}

export default Footer
