
const user = JSON.parse(localStorage.getItem('profile'))

export const initialState = {
    items: [
        {Drawing: '',
        quantity: 0, 
        material:'',
        cutCode: '',
        secondOperation: '',
        length: 0,
        width:0,
        thickness:0,
        weight:0,
        kGperUnit:0,
        unitPrice: 0,
        },
    ],
    total: 0,
    notes: user?.userProfile?.paymentDetails,
    rates: '',
    vat: 15,
    currency: '',
    invoiceNumber: Math.floor(Math.random() * 100000),
    status: '',
    type: 'Quote',
    creator: '',
}
